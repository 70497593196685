<template>
  <div>
    <h1 class="header-title mb-3">Credit Cards</h1>
    <alert v-if="publicSettings?.loading" class="py-8-px light-shadow"></alert>
    <div v-else-if="publicSettings?.data?.STRIPE_ENABLED">
      <button
        v-if="!$route.meta.allUsers"
        class="btn btn-outline-blue mb-3"
        @click="addCreditCard"
      >
        <icon type="plus" /> Add Credit Card
      </button>
      <alert class="my-4" v-if="creditCards.isLoading" />
      <alert
        class="my-4"
        v-if="!creditCards.isLoading && creditCards?.data?.length == 0"
        :hideLoader="true"
        >No Credit Cards</alert
      >
      <div
        class="card border-0 overflow-auto mobile-tablet-no-card p-2 p-md-0"
        v-if="!creditCards.isLoading && creditCards?.data?.length > 0"
      >
        <table class="table table-striped mb-0 responsive-mobile-tablet-table">
          <thead>
            <tr>
              <th>Card Type</th>
              <th>Card Ending In</th>
              <th>Expiry</th>
              <th>Last Used</th>
              <th>Default</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="creditCard in creditCards?.data" :key="creditCard.id">
              <td>
                <b class="mobile-tablet-only mr-2">Card Type</b>
                <span class="text-capitalize">{{ creditCard.brand }}</span>
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Card Ending In</b>
                <span>{{ creditCard.last4 }}</span>
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Expiry</b>
                <span
                  >{{ creditCard.expiration_month }}/{{
                    creditCard.expiration_year
                  }}</span
                >
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Last Used</b>
                <span>{{
                  creditCard.last_used && prettyDate(creditCard.last_used)
                }}</span>
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Default</b>
                <span v-if="creditCard.is_default" class="text-success h3">
                  <icon type="check" />
                </span>
                <span v-else class="text-danger h3">
                  <icon type="times" />
                </span>
              </td>
              <td class="text-right td-actions">
                <button
                  class="btn btn-danger btn-sm"
                  @click="deleteCreditCard(creditCard)"
                  :disabled="creditCardDeleteLoading === creditCard.id"
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    v-if="creditCardDeleteLoading === creditCard.id"
                  ></span>
                  <icon v-else type="trash" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div
      class="
        d-flex
        align-items-center
        justify-content-center
        redirect-to-stripe
      "
      v-else
    >
      <span class="mr-2">Please enable Stripe from the following page</span>
      <router-link
        :to="{ name: 'stripe.config' }"
        class="nav-link p-0"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        >Stripe Configuration</router-link
      >
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import helpers from "@/utils/helpers";
export default {
  name: "CreditCards",
  computed: {
    ...mapState({
      patients: (state) => state.patients,
      creditCards: (state) => state.creditCards,
      creditCardDeleteLoading: (state) =>
        state.creditCards.creditCardDeleteLoading,
      patient: (state) => state.patients.patient,
      publicSettings: (state) => state.settings.publicSettings,
    }),
  },
  mounted() {
    this.getPublicSettings();
    this.getCreditCards(
      this.$route.meta.allUsers ? undefined : this.patient?.user_id
    );
  },
  methods: {
    ...mapActions({
      getCreditCards: "creditCards/getCreditCards",
      removeCreditCard: "creditCards/removeCreditCard",
      getPublicSettings: "settings/getPublicSettings",
    }),
    addCreditCard: function () {
      this.$router.push({
        name: "creditCards.create",
        params: { id: this.patient?.id },
        query: { src: "credit-card" },
      });
    },
    deleteCreditCard: function (creditCard) {
      this.$swal({
        title: "Are you sure",
        text: "Are you sure you want to delete this Credit Card?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
      }).then((result) => {
        if (result.value) {
          this.removeCreditCard({
            id: creditCard.id,
          });
        }
      });
    },
    prettyDate: function (date) {
      return helpers.prettyDate(date, "MMM D YYYY, h:mm A");
    },
  },
};
</script>
